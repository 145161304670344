import Vue from 'vue';
import Router from 'vue-router';
import GamesOps from '../pages/GamesOps';
import advancedJackpotSystem from './advancedJackpotSystem';
import gamesInstallService from './gamesInstallService';
import crashCashRegistry from './crashCashRegistry';
import centralProductRegistry from './centralProductRegistry';
import rouletteConfig from './rouletteConfig';
import virtualDragRacesRegistry from './virtualDragRacesRegistry';
import taxIntegrationsService from './taxIntegrationsService';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'GamesOps',
      component: GamesOps,
      children: [
        advancedJackpotSystem,
        gamesInstallService,
        centralProductRegistry,
        rouletteConfig,
        crashCashRegistry,
        virtualDragRacesRegistry,
        taxIntegrationsService,
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
});
