import types from './mutationTypes';

export default {
  showLoading({ commit }) {
    commit(types.SET_LOADING, true);
  },
  hideLoading({ commit }) {
    commit(types.SET_LOADING, false);
  },
  handleRestError({ state, commit }, payload) {
    if (payload.response) {
      const { data } = payload.response;
      if (Array.isArray(data)) {
        data.forEach((response) => {
          commit(types.SHOW_NOTIFICATION, {
            type: state.notificationTypes.ERROR,
            text: response.message,
          });
        });
      } else if (data.invalidParameters) {
        data.invalidParameters.forEach((invalidParameter) => {
          commit(types.SHOW_NOTIFICATION, {
            type: state.notificationTypes.ERROR,
            text: invalidParameter.message,
          });
        });
      } else {
        commit(types.SHOW_NOTIFICATION, {
          type: state.notificationTypes.ERROR,
          text: data.error || data.message || data,
        });
      }
    } else {
      commit(types.SHOW_NOTIFICATION, {
        type: state.notificationTypes.ERROR,
        text: payload.message,
      });
    }
  },
  showNotification({ commit }, payload) {
    commit(types.SHOW_NOTIFICATION, payload);
  },
  hideNotification({ commit }, payload) {
    commit(types.HIDE_NOTIFICATION, payload);
  },
  copyToClipboard({ state, commit }, payload) {
    if (!payload) {
      return;
    }

    if (!navigator.clipboard) {
      commit(types.SHOW_NOTIFICATION, { type: state.notificationTypes.ERROR });
      return;
    }

    navigator.clipboard
      .writeText(payload)
      .then(() => {
        commit(types.SHOW_NOTIFICATION, {
          translationKey: 'copiedToClipboard',
          type: state.notificationTypes.INFO,
        });
      })
      .catch(() => {
        commit(types.SHOW_NOTIFICATION, { type: state.notificationTypes.ERROR });
      });
  },
};
