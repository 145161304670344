import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  accountsTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.accountsTableHeaders.map(convert);
  },
  accounts: (state) => state.accounts,

  wheelsTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };

    return state.wheelsTableHeaders.map(convert);
  },
  wheels: (state) => state.wheels,

  taxPoliciesTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.taxPoliciesTableHeaders.map(convert);
  },
  taxPolicies: (state) => state.taxPolicies,

  wheelAccount: (state) => state.currentWheelAccount,

  supportedPayInTaxes: (state) => state.supportedPayInTaxes,
  supportedPayOutTaxes: (state) => state.supportedPayOutTaxes,

};
