export default {
  path: '/roulette-config',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'RCDashboard',
      component: () => import('../pages/rouletteConfig/Dashboard'),
    },
  ],
  meta: {
    breadCrumb: 'rouletteConfig',
    icon: 'mdi-dice-multiple',
    imageClass: 'roulette-logo',
    isImage: true,
  },
};
