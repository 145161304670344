import auth from './auth';
import advancedJackpotSystem from './advancedJackpotSystem';
import gamesInstallService from './gamesInstallService';
import crashCashRegistry from './crashCashRegistry';
import centralProductRegistry from './centralProductRegistry';
import vdrRegistry from './virtualDragRacesRegistry';
import rouletteConfig from './rouletteConfig';
import freeBetsService from './freeBetsService';
import taxIntegrationsService from './taxIntegrationsService';

export default {
  auth,
  advancedJackpotSystem,
  gamesInstallService,
  crashCashRegistry,
  vdrRegistry,
  centralProductRegistry,
  rouletteConfig,
  freeBetsService,
  taxIntegrationsService,
};
