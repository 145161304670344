import { assign } from 'lodash';

export default {
  path: '/central-product-registry',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'CPR',
      component: () => import('../pages/centralProductRegistry/Dashboard'),
    },
    {
      path: ':productInstanceId/details',
      name: 'CPRDetails',
      component: () => import('../pages/centralProductRegistry/Details'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'orderStatusDetails',
      },
    },
  ],
  meta: {
    breadCrumb: 'cpr',
    icon: 'mdi-connection',
  },
};
