import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';

export default {
  async getLonCompanies({ commit, dispatch }) {
    try {
      const response = await api.taxIntegrationsService.getLonCompanies();
      commit(types.SET_LON_COMPANIES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getRoCompanies({ commit, dispatch }) {
    try {
      const response = await api.taxIntegrationsService.getRoCompanies();
      commit(types.SET_RO_COMPANIES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getDeCompanies({ commit, dispatch }) {
    try {
      const response = await api.taxIntegrationsService.getDeCompanies();
      commit(types.SET_DE_COMPANIES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getSupportedTaxIntegrations({ commit, dispatch }) {
    try {
      const response = await api.taxIntegrationsService.getSupportedTaxIntegrations();
      commit(types.SET_SUPPORTED_TAX_INTEGRATIONS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getCompanyTaxDetails({ commit, dispatch }, payload) {
    try {
      const response = await api.taxIntegrationsService.getCompanyTaxDetails(payload.dc, payload.company.uuid);
      commit(types.SET_COMPANY_TAX_DETAILS, { company: payload.company, taxDetails: response.data });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async updateCompanyTaxDetails({ commit, dispatch }, payload) {
    try {
      commit(rootTypes.SET_LOADING, true, { root: true });
      await api.taxIntegrationsService.updateCompanyTaxDetails(payload.dc, payload.company.uuid, payload.company);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
};
