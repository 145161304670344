import { isValidUUID } from '../util/uuid';
import http from './http';

export default {
  async getJackpotConfigs(page, size, identifier) {
    let route = `advanced-jackpot-system/jackpotconfig/?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&uuid=${identifier}`;
      } else {
        route += `&name=${identifier}`;
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getJackpotConfigByUuid(uuid) {
    const response = await http.get(`advanced-jackpot-system/jackpotconfig/${uuid}`);
    return response;
  },
  async createJackpot(jackpot) {
    const response = await http.post('advanced-jackpot-system/jackpotconfig', jackpot);
    return response;
  },
  async updateJackpot(jackpot) {
    const response = await http.patch('advanced-jackpot-system/jackpotconfig', jackpot);
    return response;
  },
  async getEnabledProducts() {
    const response = await http.get('advanced-jackpot-system/products');
    return response;
  },
  async getAJSTicketToggleStatus(cpvUUID) {
    const route = `nsoft-games-service/ticket-sending-status?gameUUID=${cpvUUID}`;
    const response = await http.get(route);
    return response;
  },
  async updateAJSTicketToggleStatus(jackpotId, gameId) {
    const response = await http.put(`nsoft-games-service/ticket-sending-status/${jackpotId}/${gameId}`);
    return response;
  },
};
