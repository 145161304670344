import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getAccounts({ commit, dispatch }) {
    try {
      const response = await api.rouletteConfig.getAccounts();
      commit(types.SET_ACCOUNTS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getWheels({ commit, dispatch }) {
    try {
      const response = await api.rouletteConfig.getWheels();
      commit(types.SET_WHEELS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getTaxPolicies({ commit, dispatch }) {
    try {
      const response = await api.rouletteConfig.getTaxPolicies();
      commit(types.SET_TAX_POLICIES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async toggleAccount({ state, commit, dispatch }, payload) {
    try {
      commit(types.UPDATE_ACCOUNT, { id: payload.id });
      const response = await api.rouletteConfig.toggleAccount(payload.id);
      commit(types.UPDATE_ACCOUNT, { id: response.data.accountId });
      commit(types.SET_ACCOUNTS, state.accounts);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getWheelAccount({ commit, dispatch }, payload) {
    try {
      commit(rootTypes.SET_LOADING, true, { root: true });
      const response = await api.rouletteConfig.getWheelAccount(payload);
      commit(types.SET_CURRENT_WHEEL_ACCOUNT, response.data.wheel);
    } catch (error) {
      commit(types.SET_CURRENT_WHEEL_ACCOUNT, undefined);
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async updateWheelAccount({ commit, dispatch }, payload) {
    try {
      await api.rouletteConfig.updateWheelAccount(payload.wheelAccountId, payload);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
};
