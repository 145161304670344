import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  tableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.tableHeaders.map(convert);
  },
  lonCompanies: (state) => state.lonCompanies,
  roCompanies: (state) => state.roCompanies,
  deCompanies: (state) => state.deCompanies,
  currentCompany: (state) => state.currentCompany,
  supportedTaxIntegrations: (state) => state.supportedTaxIntegrations,
};
