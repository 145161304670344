export default {
  tableHeaders: [
    { translationKey: 'uuid', value: 'uuid' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'displayName', value: 'displayName' },
    { translationKey: 'actions', value: 'actions' },
  ],
  jackpots: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentJackpotConfig: {
    uuid: undefined,
    name: undefined,
    displayName: undefined,
    description: undefined,
    enabled: true,
    dataCenter: 'dc1_london',
    currency: 'EUR',
    primaryRatio: 60,
    secondaryRatio: 40,
    betshopSmallestUnit: false,
    fundConfigs: [],
    productConfigs: [],
    pools: [],
  },
  uniqueGames: [],
  channels: [
    { id: 1, translationKey: 'retail' },
    { id: 2, translationKey: 'terminal' },
    { id: 3, translationKey: 'web' },
    { id: 4, translationKey: 'mobile' },
  ],
  supportedProducts: [],
};
