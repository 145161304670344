import axios from 'axios';
import interceptor from './interceptors';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 15000,
});

http.interceptors.request.use(...interceptor.request);

export default http;
