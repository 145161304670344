import { isValidUUID } from '../util/uuid';

import http from './http';

export default {
  async getProductInstances(page, size, identifier) {
    let route = `crash-cash-registry/configuration/productinstances?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&productInstanceId=${identifier}`;
      } else {
        route += `&productInstanceName=${identifier}`;
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getSubscriptions(page, size, productInstanceIds) {
    let route = `crash-cash-registry/configuration/subscriptions?page=${page}&size=${size}`;

    if (productInstanceIds) {
      route += '&productInstanceIds=';
      for (let i = 0; i < productInstanceIds.length; i += 1) {
        route += `${productInstanceIds[i]}`;
        if ((i + 1) < productInstanceIds.length) {
          route += ',';
        }
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getProductInstanceConfiguration(productInstanceId) {
    const response = await http.get(`crash-cash-registry/configuration/productinstance-details/${productInstanceId}`);
    return response;
  },
  async getProductSubscriptions(productInstanceId) {
    const response = await http.get(`crash-cash-registry/configuration/productinstance/${productInstanceId}/subscriptions`);
    return response;
  },
  async getSubscriptionConfiguration(subscriptionId, subscriptionChannel) {
    const response = await http.get(`crash-cash-registry/configuration/subscription-details/${subscriptionId}/${subscriptionChannel}`);
    return response;
  },
  async updateProductInstanceConfiguration(productInstance) {
    const response = await http.put('crash-cash-registry/configuration/productinstance', productInstance);
    return response;
  },
  async updateSubscriptionConfiguration(subscription) {
    const response = await http.put('crash-cash-registry/configuration/subscription', subscription);
    return response;
  },
  async getSupportedRtpConfigurations() {
    const response = await http.get('crash-cash-registry/configuration/rtp-configurations');
    return response;
  },
  async getSupportedPayInTaxes() {
    const response = await http.get('crash-cash-registry/taxes?taxType=PAYIN');
    return response;
  },
  async getSupportedPayOutTaxes() {
    const response = await http.get('crash-cash-registry/taxes?taxType=PAYOUT');
    return response;
  },
  async stopSubscription(subscriptionId) {
    const response = await http.put(`crash-cash-registry/tenants/subscriptions/${subscriptionId}/actions/deactivate`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await http.put(`crash-cash-registry/tenants/subscriptions/${subscriptionId}/actions/activate`);
    return response;
  },
};
