import types from './mutationTypes';

export default {
  [types.SET_LON_COMPANIES](state, data) {
    state.lonCompanies = [];
    if (data) {
      state.lonCompanies = data;
    }
  },
  [types.SET_RO_COMPANIES](state, data) {
    state.roCompanies = [];
    if (data) {
      state.roCompanies = data;
    }
  },
  [types.SET_DE_COMPANIES](state, data) {
    state.deCompanies = [];
    if (data) {
      state.deCompanies = data;
    }
  },
  [types.SET_SUPPORTED_TAX_INTEGRATIONS](state, data) {
    state.supportedTaxIntegrations = [];
    if (data) {
      state.supportedTaxIntegrations = data.map((taxIntegration) => ({ id: taxIntegration, name: taxIntegration }));
    }
  },
  [types.SET_COMPANY_TAX_DETAILS](state, data) {
    state.currentCompany = [];
    if (data) {
      state.currentCompany = {
        uuid: data.company.uuid,
        name: data.company.name,
        taxType: data.taxDetails.activeTax,
        sendTaxSyncChannels: data.taxDetails.sendTaxSyncChannels,
        sendTaxWalletChannels: data.taxDetails.sendTaxWalletChannels,
      };
    }
  },
};
