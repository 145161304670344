import { isNil } from 'lodash';
import Vue from 'vue';
import Keycloak from 'keycloak-js';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

(async () => {
  const authState = store.state.auth;
  const keycloak = new Keycloak(authState.keycloakConfig);
  keycloak.init(authState.keycloakOptions).then((auth) => {
    if (isNil(auth)) {
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');

      store.dispatch('auth/setKeycloak', keycloak);
      store.dispatch('auth/setKeycloakRefresh');
    }
  });
})();
