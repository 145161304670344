import { findIndex } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_ACTIVE_FREE_BETS](state, data) {
    state.activeFreeBets = [];
    if (data) {
      state.activeFreeBets = data;
      for (let index = 0; index < state.activeFreeBets.length; index += 1) {
        state.activeFreeBets[index].id = `${state.activeFreeBets[index].awardId} - ${state.activeFreeBets[index].playerId}`;
      }
    }
  },
  [types.SET_EXPIRED_FREE_BETS](state, data) {
    state.expiredFreeBets = [];
    if (data) {
      state.expiredFreeBets = data;
      for (let index = 0; index < state.expiredFreeBets.length; index += 1) {
        state.expiredFreeBets[index].id = `${state.expiredFreeBets[index].awardId} - ${state.expiredFreeBets[index].playerId}`;
      }
    }
  },
  [types.UPDATE_ACTIVE_FREE_BET](state, data) {
    const updatedFreeBetIndex = findIndex(state.activeFreeBets, {
      awardId: data.awardId,
      gameId: data.gameId,
      playerId: data.playerId,
      betStake: data.betStake,
    });

    if (updatedFreeBetIndex >= 0) {
      state.activeFreeBets[updatedFreeBetIndex].isManuallyExpired = data.isManuallyExpired;
    }
  },
  [types.ADD_ACTIVE_AWARD_DETAILS](state, data) {
    const updatedFreeBetIndex = findIndex(state.activeFreeBets, {
      awardId: data.awardId,
      gameId: data.gameId,
      playerId: data.playerId,
      betStake: data.betStake,
    });

    if (updatedFreeBetIndex >= 0) {
      state.activeFreeBets[updatedFreeBetIndex].tickets = data.tickets;
    }
  },
  [types.ADD_EXPIRED_AWARD_DETAILS](state, data) {
    const updatedFreeBetIndex = findIndex(state.expiredFreeBets, {
      awardId: data.awardId,
      gameId: data.gameId,
      playerId: data.playerId,
      betStake: data.betStake,
    });

    if (updatedFreeBetIndex >= 0) {
      state.expiredFreeBets[updatedFreeBetIndex].tickets = data.tickets;
    }
  },
};
