import en from 'vuetify/es5/locale/en';

export default {
  ...en,

  // APP
  save: 'Save',
  confirm: 'Confirm',
  cancel: 'Cancel',
  update: 'Update',
  create: 'Create',
  requiredField: 'Field is required',
  notValidField: 'Field is not valid',
  notValidUUIDField: 'UUID is not valid',
  notValidDecimalFormat: 'Invalid number format (up to 2 decimal digits)',
  hasToBeGreaterThanBetMinPayin: 'Value has to be greater than bet minimum payment',
  hasToBeLessThanBetMaxPayin: 'Value has to be less than bet maximum payment',
  hasToBeGreaterThanTicketMaxPayin: 'Value has to be greater than bet maximum payment',
  hasToBeLessThanTicketMaxPayin: 'Value has to be less than bet maximum payment',
  requiresIntegers: 'Value has to be a round number',
  requiresUniqueValues: 'Values have to be unique',
  select: 'Select',
  close: 'Close',
  success: 'Success',
  info: 'Info',
  error: 'Error',
  warning: 'Warning',
  copiedToClipboard: 'Successfully copied to clipboard',
  toggleAll: 'Toggle all',
  actions: 'Actions',
  areYouSure: 'Are you sure?',
  hours: 'Hours',
  optional: 'Optional',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  expiresAt: 'Expires at',
  expiredAt: 'Expired at',

  // GENERAL
  id: 'ID',
  uuid: 'UUID',
  uuid1: 'UUID 1',
  uuid2: 'UUID 2',
  cpvUUID: 'cpvUUID',
  accountId: 'Account ID',
  gameId: 'Game ID',
  name: 'Name',
  displayName: 'Display name',
  email: 'Email',
  created: 'Created',
  type: 'Type',
  version: 'Version',
  status: 'Status',
  location: 'Location',
  joined: 'Joined',
  enabled: 'Enabled',
  dataCenter: 'Data center',
  currency: 'Currency',
  channelName: 'Channel name',
  products: 'Products',
  productName: 'Product name',
  configure: 'Configure',
  retail: 'Retail',
  terminal: 'Terminal',
  web: 'Web',
  mobile: 'Mobile',
  subscription: 'Subscription',
  subscriptionId: 'Subscription ID',
  subscriptionDetails: 'Subscription details',
  subscriptionConfig: 'Subscription configuration',
  productInstanceId: 'Product instance ID',
  productInstanceName: 'Product instance name',
  productInstance: 'Product instance',
  productInstanceDetails: 'Product instance details',
  productInstanceConfig: 'Product instance configuration',
  productInstances: 'Product instances',
  start: 'Start',
  stop: 'Stop',
  rtpConfiguration: 'RTP configuration',
  rtpPercentage: 'RTP Percentage',
  maxBetAmount: 'Max bet amount',
  minBetAmount: 'Min bet amount',
  maxBetCount: 'Max bet count',
  ticketRetirement: 'Ticket retirement',
  maxPossibleWin: 'Max possible win',
  startDuration: 'Start duration',
  countdownDuration: 'Countdown duration',
  payInTax: 'Pay in tax',
  payOutTax: 'Pay out tax',
  ticketMaxPayment: 'Ticket maximum payment',
  ticketMinPayment: 'Ticket minimum payment',
  betMaxPayment: 'Bet maximum payment',
  betMinPayment: 'Bet minimum payment',
  taxCalculationDisabled: 'Tax calculation is disabled',
  isEnabled: 'Is enabled',
  isInstalled: 'Is installed',
  title: 'Title',
  taxId: 'Tax ID',
  gameName: 'Game name',
  playerId: 'Player ID',
  betAmount: 'Bet amount',
  barcode: 'Barcode',

  // Cash crash config
  crashCashConfigs: 'Crash Cash Configs',
  crashCashConfig: 'Crash Cash Config',
  crashCashConfigDetails: 'Crash Cash Config Details',
  editCrashCashConfig: 'Edit Crash Cash Config',
  isAutoBetEnabled: 'Is auto bet enabled',
  isAutoCashOutEnabled: 'Is auto cash out enabled',
  bonusWheelTurnDuration: 'Bonus wheel turn duration',
  goToVisualization: 'Go to visualization',
  productIdOrName: 'Product ID or name',
  bettingDisabledThreshold: 'Betting disabled treshold (s)',
  promoRainClaimTimeLimit: 'Promo rain claim time limit',
  requiredNumberOfEvents: 'Required number of events',
  baseGameRTP: 'Base game RTP',
  promoRainRTP: 'Promo rain RTP',
  luckyRoundRTP: 'Lucky round RTP',
  luckyRoundProbability: 'Lucky round multipliers',

  // VDR Config
  vdrConfigs: 'VDR Configs',
  vdrConfig: 'Virtual Drag Races Config',
  vdrConfigDetails: 'VDR Config Details',
  bettingDisabledDuration: 'Betting disabled duration',
  resultsDuration: 'Results duration',
  editVDRConfig: 'Edit VDR Config',

  // AJS
  ajs: 'Advanced Jackpot System',
  jackpot: 'Jackpot',
  jackpotName: 'Jackpot name',
  jackpotDetails: 'Jackpot details',
  newJackpot: 'Create jackpot',
  editJackpot: 'Edit jackpot',
  fundConfig: 'Fund config',
  addFundConfig: 'Add new fund config',
  pool: 'Pool',
  addPool: 'Add new pool',
  lowerLimit: 'Lower limit',
  upperLimit: 'Upper limit',
  payinLimit: 'Payin limit',
  transferredLimit: 'Transferred limit',
  transferredAmount: 'Transferred amount',
  minimumStart: 'Minimum start',
  locationBased: 'Location based',
  primaryRatio: 'Primary ratio',
  secondaryRatio: 'Secondary ratio',
  displayTime: 'Display time',
  hasImages: 'Has images',
  betshopSmallestUnit: 'Betshop smallest unit',
  showPreviousJackpots: 'Show previous jackpots',
  showLocationDetails: 'Show location details',
  showLastJackpot: 'Show last jackpot',
  waitForCountdown: 'Wait for countdown',
  displayColor: 'Display color',
  mainColor: 'Main color',
  secondaryColor: 'Secondary color',
  textColorOne: 'Text color 1',
  textColorTwo: 'Text color 2',
  priorityId: 'Priority',
  fundNumber: 'Fund number',
  addProductConfig: 'Add product config',
  contribution: 'Contribution',
  checkTicketToggleStatus: 'Check ticket sending status',
  ticketSendingStatus: 'Ticket sending status',
  locationBasedToggledAndTransferredValuesWarning: 'Are you sure? Toggling location based property will create new funds with the starting fund amount and jackpot win limit as defined in the transferred values.',
  addLocation: 'Add location',
  locationId: 'Location ID',
  jackpotIdOrName: 'Jackpot ID or name',

  // INSTANCES
  instances: 'Instances',

  // OPS TOOLS
  opsTools: 'OPS Tools',

  // SERVICES
  services: 'Services',

  // USERS
  users: 'Users',
  user: 'User',
  newUser: 'Create user',

  // GIS
  gis: 'Games Install Service',
  platformAccountDetails: 'Platform installation details',
  platformAccount: 'Company',
  platformAccountName: 'Company name',
  serviceAccount: 'Game',
  serviceAccountSubscribers: 'Company games',
  platformProvider: 'Company platform provider',
  installPlatformAccount: 'Install company',
  activatePlatformAccount: 'Activate company',
  serviceAccountSubscription: 'Game',
  installServiceAccountSubscription: 'Install game',
  pleaseCheckInstallationStatusLater: 'Please check installation status in a few minutes',
  pleaseCheckforGamesLater: 'Please check for products in a few minutes',
  installationRule: 'Installation service processes new installations only from 7AM to 12PM, with 15 minute retry interval',
  companyIdOrName: 'Company ID or name',

  // CPR
  cpr: 'Central Product Registry Service',
  orderStatus: 'Order status',
  orderStatusDetails: 'Order status details',
  installProductOrder: 'Install product order',
  deployment: 'Deployment',

  // Roulette
  rouletteConfig: 'Roulette config',
  accounts: 'Accounts',
  wheels: 'Wheels',
  taxes: 'Taxes',
  taxPolicies: 'Tax policies',
  platform: 'Platform',
  platformProductId: 'Platform product ID',
  companyId: 'Company ID',
  wheelId: 'Wheel ID',
  wheelAccountId: 'Wheel account ID',
  createWheelAccount: 'Create wheel',
  editWheelAccount: 'Edit wheel account',
  chips: 'Chips',
  requiresFiveSelections: 'Five chips are required',
  hasToBeSmallerThanTheGreatestChip: 'Value has to be equal or smaller than the greatest chip',
  hasToBeGreaterThanTheSmallestChip: 'Value has to be equal or greater to the lowest chip',

  // Free bets service
  freeBetsService: 'Free bets service',
  activeFreeBets: 'Active free bets',
  expiredFreeBets: 'Expired free bets',
  awardId: 'Award ID',
  totalCount: 'Total count',
  remainingCount: 'Remaining count',
  usedCount: 'Used count',
  expireManually: 'Expire manually',
  isManuallyExpired: 'Is manually expired',
  showTickets: 'Show tickets',

  // Tax integrations
  taxIntegrations: 'Tax integrations',
  taxIntegration: 'Tax integration',
  editCompanyTax: 'Edit company tax',
  sendTaxSyncChannels: 'Channels for tax sync',
  sendTaxWalletChannels: 'Channels for tax wallet',

};
