export default {
  path: '/tax-integrations',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'TIDashboard',
      component: () => import('../pages/taxIntegrationsService/Dashboard'),
    },
  ],
  meta: {
    breadCrumb: 'taxIntegrations',
    icon: 'mdi-bank',
  },
};
