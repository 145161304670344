import types from './mutationTypes';
import getters from './getters';

export default {
  [types.SET_JACKPOT_CONFIGS](state, data) {
    if (data) {
      state.jackpots.data = data.data;
      state.jackpots.pagination = data.pagination;
    } else {
      state.jackpots.data = undefined;
      state.jackpots.pagination = undefined;
    }
  },
  [types.SET_JACKPOT_CONFIG_DETAILS](state, data) {
    if (!data) {
      state.currentJackpotConfig = data;
      return;
    }

    state.currentJackpotConfig = data;
  },
  [types.SET_SUPPORTED_PRODUCTS](state, data) {
    state.supportedProducts = data;
  },
  [types.ADD_NEW_FUND_CONFIG](state, data) {
    if (getters.hasMaxFundConfigs(state)) {
      return;
    }

    const fundConfig = data;
    const hasFirstFundConfigNumber = state.currentJackpotConfig.fundConfigs
      .some((fc) => fc.fundNumber === 1);
    if (hasFirstFundConfigNumber) {
      const hasSecondFundConfigNumber = state.currentJackpotConfig.fundConfigs
        .some((fc) => fc.fundNumber === 2);
      if (hasSecondFundConfigNumber) {
        fundConfig.fundNumber = 3;
      } else {
        fundConfig.fundNumber = 2;
      }
    } else {
      fundConfig.fundNumber = 1;
    }

    const hasFirstFundConfigPriority = state.currentJackpotConfig.fundConfigs
      .some((fc) => fc.priorityId === 1);
    if (hasFirstFundConfigPriority) {
      const hasSecondFundConfigPriority = state.currentJackpotConfig.fundConfigs
        .some((fc) => fc.priorityId === 2);
      if (hasSecondFundConfigPriority) {
        fundConfig.priorityId = 3;
      } else {
        fundConfig.priorityId = 2;
      }
    } else {
      fundConfig.priorityId = 1;
    }

    // eslint-disable-next-line default-case
    switch (fundConfig.fundNumber) {
      case 1: {
        fundConfig.displayColor = '#4560E6';
        fundConfig.secondaryColor = '#4560E6';
        fundConfig.textColorOne = '#1F1F1F';
        fundConfig.textColorTwo = '#1F1F1F';
        break;
      }
      case 2: {
        fundConfig.displayColor = '#E64545';
        fundConfig.secondaryColor = '#E64545';
        fundConfig.textColorOne = '#1F1F1F';
        fundConfig.textColorTwo = '#FFFFFF';
        break;
      }
      case 3: {
        fundConfig.displayColor = '#E6C545';
        fundConfig.secondaryColor = '#E6C545';
        fundConfig.textColorOne = '#1F1F1F';
        this.textColorTwo = '#FFFFFF';
      }
    }

    if (!state.currentJackpotConfig.fundConfigs) {
      state.currentJackpotConfig.fundConfigs = [];
    }

    state.currentJackpotConfig.fundConfigs.push(fundConfig);
  },
  [types.ADD_NEW_POOL](state, data) {
    if (!state.currentJackpotConfig.pools) {
      state.currentJackpotConfig.pools = [];
    }

    state.currentJackpotConfig.pools.push(data);
  },
  [types.ADD_NEW_PRODUCT_CONFIG](state, data) {
    const fundConfigExists = state.currentJackpotConfig.fundConfigs
      .some((fundConfig) => fundConfig.fundNumber === data.fundNumber);
    if (!fundConfigExists) {
      return;
    }

    if (!state.currentJackpotConfig.productConfigs) {
      state.currentJackpotConfig.productConfigs = [];
    }

    state.currentJackpotConfig.productConfigs.push(data);
  },
  [types.ADD_NEW_LOCATION](state, data) {
    const poolExists = state.currentJackpotConfig.pools
      .some((pool) => pool.id === data.poolId);
    if (!poolExists) {
      return;
    }

    if (!state.currentJackpotConfig.pools) {
      state.currentJackpotConfig.pools = [];
    }

    state.currentJackpotConfig.pools
      .find((pool) => pool.id === data.poolId)
      .locations.push(data.location);
  },
  [types.DELETE_FUND_CONFIG](state, data) {
    const fundIndex = state.currentJackpotConfig.fundConfigs
      .findIndex((fundConfig) => fundConfig.fundNumber === data);
    if (fundIndex < 0) {
      return;
    }

    state.currentJackpotConfig.fundConfigs.splice(fundIndex, 1);
    state.currentJackpotConfig.productConfigs = state.currentJackpotConfig.productConfigs
      .filter((productConfig) => productConfig.fundNumber !== data);
  },
  [types.DELETE_PRODUCT_CONFIG](state, data) {
    const productIndex = state.currentJackpotConfig.productConfigs
      .findIndex((productConfig) => productConfig.accountId === data.accountId
        && productConfig.channel === data.channel
        && productConfig.fundNumber === data.fundNumber
        && productConfig.gameId === data.gameId);

    if (productIndex < 0) {
      return;
    }

    state.currentJackpotConfig.productConfigs.splice(productIndex, 1);
  },
  [types.DELETE_POOL](state, data) {
    const poolIndex = state.currentJackpotConfig.pools
      .findIndex((pool) => pool.id === data);
    if (poolIndex < 0) {
      return;
    }

    state.currentJackpotConfig.pools.splice(poolIndex, 1);
  },
  [types.DELETE_LOCATION](state, data) {
    const { poolId } = data;
    const poolIndex = state.currentJackpotConfig.pools
      .findIndex((pool) => pool.id === poolId);
    if (poolIndex < 0) {
      return;
    }

    const { locationId } = data;
    const locationIndex = state.currentJackpotConfig.pools[poolIndex].locations
      .findIndex((location) => location.id === locationId);
    if (locationIndex < 0) {
      return;
    }

    state.currentJackpotConfig.pools[poolIndex].locations.splice(locationIndex, 1);
  },
  [types.CALCULATE_CONTRIBUTION_COLOR](state, data) {
    const productIndex = state.currentJackpotConfig.productConfigs
      .findIndex((productConfig) => productConfig.accountId === data.accountId
        && productConfig.channel === data.channel
        && productConfig.fundNumber === data.fundNumber
        && productConfig.gameId === data.gameId);

    if (productIndex < 0) {
      return;
    }

    let newContributionColor;
    if (!data.contribution || data.contribution <= 0) {
      newContributionColor = data.contributionColor || undefined;
    } else if (data.contribution < 1) {
      newContributionColor = 'green';
    } else if (data.contribution < 2) {
      newContributionColor = 'orange';
    } else {
      newContributionColor = 'red';
    }

    state.currentJackpotConfig
      .productConfigs[productIndex].contributionColor = newContributionColor;
  },
  [types.SET_UNIQUE_GAMES](state, data) {
    if (!data) {
      state.uniqueGames = [];
      return;
    }

    state.uniqueGames = [];
    let i = 0;
    data.games.entries().forEach((game) => {
      state.uniqueGames.push({
        gameId: game[0],
        productName: game[1],
        toggledOn: data.statuses[i],
      });
      i += 1;
    });
  },
  [types.SET_TICKET_SENDING_STATUS](state, data) {
    const uniqueGame = state.uniqueGames.find((game) => game.gameId === data.gameId);
    uniqueGame.toggledOn = !uniqueGame.toggledOn;
  },
};
