import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getPlatformAccounts({ commit, dispatch }, payload) {
    try {
      const response = await api.gamesInstallService.getPlatformAccounts(
        payload.page,
        payload.size,
        payload.identifier,
        payload.status,
      );
      commit(types.SET_PLATFORM_ACCOUNTS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getPlatformAccountInstallationByUuid({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      const response = await api.gamesInstallService.getPlatformAccountInstallationByUuid(payload);
      const deactivatedCompanyDetails = await api.gamesInstallService.getCompanyDeactivatedStatus(payload);
      commit(types.SET_PLATFORM_ACCOUNT_INSTALLATION_DETAILS, {
        data: response.data,
        isDeactivated: deactivatedCompanyDetails.data,
      });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  removePlatformAccountInstallation({ commit }) {
    commit(types.SET_PLATFORM_ACCOUNT_INSTALLATION_DETAILS, undefined);
  },
  async installPlatformAccount({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.gamesInstallService.installPlatformAccount(
        payload.platformAccount,
        payload.selectedPlatformHttpRoute,
        payload.selectedPlatformProviderName,
      );
      commit(types.SET_PLATFORM_ACCOUNT_INSTALLATION_STATUS, true);
      commit(rootTypes.SHOW_NOTIFICATION, {
        translationKey: 'pleaseCheckforGamesLater',
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async installServiceAccountSubscription({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      const response = await api.gamesInstallService.installServiceAccountSubscription(
        payload.serviceAccountSubscription,
        payload.selectedServiceHttpRoute,
      );
      commit(types.SET_SERVICE_ACCOUNT_SUBSCRIPTION_INSTALLATION_STATUS, {
        serviceAccountSubscriptionUuid: response.data,
        status: true,
      });
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.INFO,
        translationKey: 'pleaseCheckInstallationStatusLater',
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async activatePlatformAccount({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.gamesInstallService.activatePlatformAccount(payload);
      commit(types.SET_PLATFORM_ACCOUNT_IS_DEACTIVATED_STATUS, false);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
};
