import { assign } from 'lodash';

/* eslint-disable */
export default {
  path: '/vdr-config',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'VDRRDashboard',
      component: () => import('../pages/virtualDragRacesRegistry/Dashboard'),
    },
    {
      path: ':productInstanceId/details',
      name: 'VDRRProductInstanceDetails',
      component: () => import('../pages/virtualDragRacesRegistry/Details'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'productInstanceDetails',
      },
    },
    {
      path: ':subscriptionId/:subscriptionChannel/details',
      name: 'VDRRSubscriptionDetails',
      component: () => import('../pages/virtualDragRacesRegistry/Details'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'subscriptionDetails',
      },
    },
    {
      path: ':productInstanceId/edit',
      name: 'VDRRProductInstanceEdit',
      component: () => import('../pages/virtualDragRacesRegistry/Edit'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'productInstanceConfig',
      },
    },
    {
      path: ':subscriptionId/:subscriptionChannel/edit',
      name: 'VDRRSubscriptionEdit',
      component: () => import('../pages/virtualDragRacesRegistry/Edit'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'subscriptionConfig',
      },
    },
  ],
  meta: {
    imageUrl: '../assets/vdr-config-logo.svg',
    imageClass: 'vdr-logo',
    breadCrumb: 'vdrConfig',
    isImage: true,
    icon: 'mdi-cog',
  },
};
