import types from './mutationTypes';

export default {
  [types.SET_PRODUCT_INSTANCES](state, data) {
    if (data) {
      state.productInstances.data = data.data;
      state.productInstances.pagination = data.pagination;
    } else {
      state.productInstances.data = undefined;
    }
  },
  [types.SET_SUBSCRIPTIONS](state, data) {
    if (data) {
      state.subscriptions.data = data.data;
      state.subscriptions.pagination = data.pagination;
    } else {
      state.subscriptions.data = undefined;
    }
  },
  [types.SET_PRODUCT_INSTANCE_CONFIGURATION](state, data) {
    state.currentVDRConfig = data;
  },
  [types.SET_PRODUCT_SUBSCRIPTIONS](state, data) {
    state.currentProductSubscriptions = data;
  },
  [types.SET_SUBSCRIPTION_CONFIGURATION](state, data) {
    state.currentVDRConfig = data;
  },
  [types.SET_SUPPORTED_PAYIN_TAXES](state, data) {
    state.supportedPayInTaxes = data;
  },
  [types.SET_SUPPORTED_PAYOUT_TAXES](state, data) {
    state.supportedPayOutTaxes = data;
  },
};
