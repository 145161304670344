export default {
  SET_PRODUCT_INSTANCES: 'SET_PRODUCT_INSTANCES',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
  SET_PRODUCT_INSTANCE_CONFIGURATION: 'SET_PRODUCT_INSTANCE_CONFIGURATION',
  SET_PRODUCT_SUBSCRIPTIONS: 'SET_PRODUCT_SUBSCRIPTIONS',
  SET_SUBSCRIPTION_CONFIGURATION: 'SET_SUBSCRIPTION_CONFIGURATION',
  SET_SUPPORTED_RTP_CONFIGURATIONS: 'SET_SUPPORTED_RTP_CONFIGURATIONS',
  SET_SUPPORTED_PAYOUT_TAXES: 'SET_SUPPORTED_PAYOUT_TAXES',
  SET_SUPPORTED_PAYIN_TAXES: 'SET_SUPPORTED_PAYIN_TAXES',
};
