export default {
  accountsTableHeaders: [
    { translationKey: 'accountId', value: 'id' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'platform', value: 'platformName' },
    { translationKey: 'platformProductId', value: 'platformProductId' },
    { translationKey: 'companyId', value: 'companyUuid' },
    { translationKey: 'currency', value: 'companyCurrencyCode' },
    { translationKey: 'wheelId', value: 'wheelId' },
    { translationKey: 'wheelAccountId', value: 'wheelAccountId' },
    { translationKey: 'isEnabled', value: 'isEnabled' },
    { translationKey: 'isInstalled', value: 'isInstalled' },
    { translationKey: 'actions', value: 'actions' },
  ],
  accounts: [],

  wheelsTableHeaders: [
    { translationKey: 'wheelId', value: 'id' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'title', value: 'title' },
    { translationKey: 'type', value: 'wheelTypeName' },
  ],
  wheels: [],

  taxPoliciesTableHeaders: [
    { translationKey: 'taxId', value: 'name' },
    { translationKey: 'type', value: 'type' },
  ],
  taxPolicies: [],

  currentWheelAccount: {
    maxBets: 10,
    ticketPayinTaxPolicy: undefined,
    ticketPayoutTaxPolicy: undefined,
    ticketMaxPayment: 2000,
    ticketMinPayment: 10,
    ticketBetMaxPayment: 200,
    ticketBetMinPayment: 10,
    chips: [10, 20, 50, 100],
    taxCalculationDisabled: false,
  },

  supportedPayInTaxes: [],
  supportedPayOutTaxes: [],

};
