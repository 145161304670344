export default {
  activeFreeBetTableHeaders: [
    { translationKey: 'awardId', value: 'awardId' },
    { translationKey: 'gameId', value: 'gameId' },
    { translationKey: 'subscriptionId', value: 'subscriptionId' },
    { translationKey: 'gameName', value: 'gameName' },
    { translationKey: 'playerId', value: 'playerId' },
    { translationKey: 'betAmount', value: 'betStake' },
    { translationKey: 'totalCount', value: 'totalCount' },
    { translationKey: 'remainingCount', value: 'remainingCount' },
    { translationKey: 'usedCount', value: 'usedCount' },
    { translationKey: 'createdAt', value: 'createdAt' },
    { translationKey: 'updatedAt', value: 'updatedAt' },
    { translationKey: 'expiresAt', value: 'expiresAt' },
    { translationKey: 'expireManually', value: 'isManuallyExpired' },
    { translationKey: 'showTickets', value: 'data-table-expand' },
  ],

  expiredFreeBetTableHeaders: [
    { translationKey: 'awardId', value: 'awardId' },
    { translationKey: 'gameId', value: 'gameId' },
    { translationKey: 'subscriptionId', value: 'subscriptionId' },
    { translationKey: 'gameName', value: 'gameName' },
    { translationKey: 'playerId', value: 'playerId' },
    { translationKey: 'betAmount', value: 'betStake' },
    { translationKey: 'totalCount', value: 'totalCount' },
    { translationKey: 'remainingCount', value: 'remainingCount' },
    { translationKey: 'usedCount', value: 'usedCount' },
    { translationKey: 'createdAt', value: 'createdAt' },
    { translationKey: 'updatedAt', value: 'updatedAt' },
    { translationKey: 'expiresAt', value: 'expiresAt' },
    { translationKey: 'expiredAt', value: 'expiredAt' },
    { translationKey: 'isManuallyExpired', value: 'isManuallyExpired' },
    { translationKey: 'showTickets', value: 'data-table-expand' },
  ],

  awardDetailsHeaders: [
    { translationKey: 'barcode', value: 'barcode' },
    { translationKey: 'channelName', value: 'deliveryPlatform' },
    { translationKey: 'usedCount', value: 'claimedCount' },
  ],

  activeFreeBets: [],
  expiredFreeBets: [],
};
