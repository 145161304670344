export default {
  isLoading: (state) => state.loading,
  notifications: (state) => state.notifications,
  colorBoxStyle: () => ({
    height: '25px',
    width: '30px',
    borderRadius: '4px',
    border: '1px dashed black',
  }),
  formRules: (state) => state.formRules,
  notificationTypes: (state) => state.notificationTypes,
};
