import http from './http';

const baseUrl = 'roulette';

export default {
  async getAccounts() {
    const response = await http.get(`${baseUrl}/account`);
    return response;
  },
  async getWheels() {
    const response = await http.get(`${baseUrl}/wheel`);
    return response;
  },
  async getTaxPolicies() {
    const response = await http.get(`${baseUrl}/tax-policy`);
    return response;
  },
  async toggleAccount(accountId) {
    const response = await http.put(`${baseUrl}/toggle-account/${accountId}`);
    return response;
  },
  async getWheelAccount(platformProductId) {
    const response = await http.get(`${baseUrl}/wheel-accounts/${platformProductId}`);
    return response;
  },
  async createWheelAccount(wheelAccount) {
    const response = await http.post(`${baseUrl}/wheel-accounts`, wheelAccount);
    return response;
  },
  async updateWheelAccount(wheelAccountId, wheelAccount) {
    const response = await http.put(`${baseUrl}/wheel-accounts/${wheelAccountId}`, wheelAccount);
    return response;
  },
};
