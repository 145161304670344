export default {
  tableHeaders: [
    { translationKey: 'companyId', value: 'uuid' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'actions', value: 'actions' },
  ],
  lonCompanies: [],
  roCompanies: [],
  deCompanies: [],
  currentCompany: undefined,
  supportedTaxIntegrations: [],
};
