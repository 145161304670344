import http from './http';

export default {
  async getActiveFreeBets() {
    const response = await http.get('promotion/free-bets/active');
    return response;
  },
  async getExpiredFreeBets() {
    const response = await http.get('promotion/free-bets/expired');
    return response;
  },
  async expireBet(awardId, playerId) {
    const response = await http.patch('promotion/free-bets/expire', { awardId, playerId });
    return response;
  },
  async getAwardDetails(awardId) {
    const response = await http.get(`promotion/free-bets/awards/${awardId}`);
    return response;
  },
};
