import http from './http';
import { isValidUUID } from '../util/uuid';

export default {
  async getProductInstances(page, size, identifier) {
    let route = `virtual-drag-races/ops/instances?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&instanceId=${identifier}`;
      } else {
        route += `&instanceName=${identifier}`;
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getSubscriptions(page, size, productInstanceIds) {
    let route = `virtual-drag-races/ops/subscriptions?page=${page}&size=${size}`;

    if (productInstanceIds) {
      route += '&productInstanceIds=';
      for (let i = 0; i < productInstanceIds.length; i += 1) {
        route += `${productInstanceIds[i]}`;
        if ((i + 1) < productInstanceIds.length) {
          route += ',';
        }
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getProductInstanceConfiguration(productInstanceId) {
    const response = await http.get(`virtual-drag-races/ops/instances/details/${productInstanceId}`);
    return response;
  },
  async getSubscriptionConfiguration(subscriptionId, subscriptionChannel) {
    const response = await http.get(`virtual-drag-races/ops/subscriptions/details/${subscriptionId}/${subscriptionChannel}`);
    return response;
  },
  async updateProductInstanceConfiguration(body, productInstanceId) {
    const response = await http.put(`virtual-drag-races/ops/instances/${productInstanceId}`, body);
    return response;
  },
  async updateSubscriptionConfiguration(subscription) {
    const response = await http.put(`virtual-drag-races/ops/subscriptions/${subscription.subscriptionId}/${subscription.channel}`,
      subscription);
    return response;
  },
  async getSupportedPayInTaxes() {
    const response = await http.get('virtual-drag-races/taxes?taxType=PAYIN');
    return response;
  },
  async getSupportedPayOutTaxes() {
    const response = await http.get('virtual-drag-races/taxes?taxType=PAYOUT');
    return response;
  },
  async stopSubscription(subscriptionId) {
    const response = await http.put(`virtual-drag-races/tenants/subscriptions/${subscriptionId}/actions/deactivate`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await http.put(`virtual-drag-races/tenants/subscriptions/${subscriptionId}/actions/activate`);
    return response;
  },
};
