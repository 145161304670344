<template>
  <transition name="fade-notification">
    <v-alert
      v-if="notificationId"
      :style="styling"
      dense
      border="left"
      colored-border
      :type="notificationType"
      elevation="2"
    >
      <span v-if="notificationText">
        {{ notificationText }}
      </span>
      <span v-else>
        {{ $vuetify.lang.t(`$vuetify.${translationKey}`) }}
      </span>
    </v-alert>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    notificationId: {
      required: true,
    },
    notificationText: {
      default: undefined,
    },
    translationKey: {
      default: undefined,
    },
    notificationType: {
      default: 'info',
    },
  },
  computed: {
    styling() {
      return {
        zIndex: '999',
      };
    },
  },
  methods: {
    ...mapActions(['hideNotification']),
  },
  mounted() {
    setTimeout(() => this.hideNotification(this.notificationId), 8000);
  },
};
</script>

<style lang="scss">
.fade-notification-enter-active,
.fade-notification-leave-active,
.fade-notification-move {
  transition: all 0.5s;
}

.fade-notification-enter,
.fade-notification-leave-to {
  opacity: 0;
}
</style>
