import { findIndex } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_ACCOUNTS](state, data) {
    state.accounts = [];
    if (data) {
      state.accounts = data;
    }
  },
  [types.SET_WHEELS](state, data) {
    if (data) {
      state.wheels = data;
    } else {
      state.wheels = [];
    }
  },
  [types.SET_TAX_POLICIES](state, data) {
    if (data) {
      const transformedTaxPolicies = [];

      data.payin.forEach((tax) => {
        transformedTaxPolicies.push({
          name: tax,
          type: 'payin',
        });

        state.supportedPayInTaxes.push({
          id: tax,
          name: tax,
        });
      });

      data.payout.forEach((tax) => {
        transformedTaxPolicies.push({
          name: tax,
          type: 'payout',
        });

        state.supportedPayOutTaxes.push({
          id: tax,
          name: tax,
        });
      });

      state.taxPolicies = transformedTaxPolicies;
    } else {
      state.taxPolicies = [];
      state.supportedPayInTaxes = [];
      state.supportedPayOutTaxes = [];
    }
  },
  [types.UPDATE_ACCOUNT](state, data) {
    const updatedAccountIndex = findIndex(state.accounts, { id: data.id });
    if (updatedAccountIndex >= 0) {
      state.accounts[updatedAccountIndex].isUpdating = !state.accounts[updatedAccountIndex].isUpdating;
    }
  },
  [types.SET_CURRENT_WHEEL_ACCOUNT](state, data) {
    if (!data) {
      state.currentWheelAccount = undefined;
      return;
    }

    state.currentWheelAccount = {
      wheelAccountId: data.wheelAccountId,
      title: data.title,
      maxBets: data.definition.tickets.maxBets,
      ticketPayinTaxPolicy: data.definition.tickets.ticketPayinTaxPolicy,
      ticketPayoutTaxPolicy: data.definition.tickets.ticketPayoutTaxPolicy,
      ticketMaxPayment: data.definition.tickets.ticketMaxPayment,
      ticketMinPayment: data.definition.tickets.ticketMinPayment,
      ticketBetMaxPayment: data.definition.tickets.ticketBetMaxPayment,
      ticketBetMinPayment: data.definition.tickets.ticketBetMinPayment,
      chips: data.definition.chips,
      taxCalculationDisabled: data.definition.taxCalculationDisabled,
    };
  },
  [types.RESET_CURRENT_WHEEL_ACCOUNT](state) {
    state.currentWheelAccount = {
      maxBets: 10,
      ticketPayinTaxPolicy: undefined,
      ticketPayoutTaxPolicy: undefined,
      ticketMaxPayment: 1000,
      ticketMinPayment: 10,
      ticketBetMaxPayment: 100,
      ticketBetMinPayment: 1,
      chips: [1, 10, 20, 50, 100],
      taxCalculationDisabled: true,
    };
  },
};
