import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  activeFreeBetsTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.activeFreeBetTableHeaders.map(convert);
  },
  expiredFreeBetsTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.expiredFreeBetTableHeaders.map(convert);
  },
  awardDetailsHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.awardDetailsHeaders.map(convert);
  },
  activeFreeBets: (state) => state.activeFreeBets,
  expiredFreeBets: (state) => state.expiredFreeBets,
};
