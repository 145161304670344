<template>
  <div>
    <MainNavigation />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
      <transition-group tag="div" name="slide-fade" class="alert-wrapper">
        <UIAlert
          v-for="notification in notifications"
          :key="notification.id"
          :notificationId="notification.id"
          :notificationText="notification.text"
          :translationKey="notification.translationKey"
          :notificationType="notification.type"
        />
      </transition-group>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UIAlert from '../components/shared/UIAlert';
import MainNavigation from '../components/shared/MainNavigation';

export default {
  name: 'GamesOps',
  components: {
    MainNavigation,
    UIAlert,
  },
  computed: {
    ...mapGetters(['notifications']),
  },
};
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: unset !important;
}

.alert-wrapper {
  position: fixed;
  bottom: 0px;
  display: block;
  overflow: hidden;
  padding: 10px;
}

.slide-fade-enter-active,
.slide-fade-leave-active,
.slide-fade-move {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}
</style>
