import { find } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_PLATFORM_ACCOUNTS](state, data) {
    if (data) {
      state.platformAccounts.data = data.data;
      state.platformAccounts.pagination = data.pagination;
    } else {
      state.platformAccounts.data = undefined;
      state.platformAccounts.pagination = undefined;
    }
  },
  [types.SET_PLATFORM_ACCOUNT_INSTALLATION_DETAILS](state, data) {
    if (!data) {
      state.currentPlatformAccount = undefined;
      state.currentServiceAccountSubscriptions = undefined;
      return;
    }

    state.currentPlatformAccount = data.data.platformAccount;
    state.currentPlatformAccount.isDeactivated = !!data.isDeactivated;
    state.currentServiceAccountSubscriptions = data.data.serviceAccountSubscriptions;
  },
  [types.SET_PLATFORM_ACCOUNT_INSTALLATION_STATUS](state, data) {
    if (!state.currentPlatformAccount) {
      return;
    }

    state.currentPlatformAccount.isInstalled = data;
  },
  [types.SET_SERVICE_ACCOUNT_SUBSCRIPTION_INSTALLATION_STATUS](state, data) {
    if (!state.serviceAccountSubscription) {
      return;
    }

    const { serviceAccountSubscriptions } = state.currentServiceAccountSubscriptions;
    const uuid = data.serviceAccountSubscriptionUuid;
    const serviceAccountSubscription = find(serviceAccountSubscriptions, { uuid });
    if (serviceAccountSubscription) {
      serviceAccountSubscription.isInstalled = data.status;
    }
  },
  [types.SET_PLATFORM_ACCOUNT_IS_DEACTIVATED_STATUS](state, data) {
    if (!state.currentPlatformAccount) {
      return;
    }

    state.currentPlatformAccount.isDeactivated = data;
  },
};
