import types from './mutationTypes';

export default {
  [types.SET_SEVEN_PRODUCT_ORDERS](state, data) {
    if (data) {
      state.sevenProductOrders.data = data.data;
      state.sevenProductOrders.pagination = data.pagination;
    } else {
      state.sevenProductOrders.data = undefined;
    }
  },
  [types.SET_SEVEN_PRODUCT_ORDER_DETAILS](state, data) {
    if (!data) {
      state.currentProductOrder = undefined;
      return;
    }

    state.currentProductOrder = data;
  },
  [types.SET_PRODUCT_DEPLOYMENTS](state, data) {
    if (!data) {
      state.currentProductDeployments = undefined;
      return;
    }

    state.currentProductDeployments = data;
  },
  [types.SET_CURRENT_PRODUCT_AS_INSTALLED](state) {
    if (!state.currentProductOrder) {
      return;
    }

    state.currentProductOrder.isInstalled = true;
    state.currentProductOrder.status = 'PENDING';
  },
};
