export default {
  productInstanceTableHeaders: [
    { translationKey: 'productInstanceId', value: 'uuid' },
    { translationKey: 'name', value: 'productInstanceName' },
    { translationKey: 'actions', value: 'actions' },
  ],
  subscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'uuid' },
    { translationKey: 'productInstanceId', value: 'productInstanceId' },
    { translationKey: 'channelName', value: 'channel' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productSubscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'uuid' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productInstances: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  subscriptions: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentCrashCashConfig: undefined,
  currentProductSubscriptions: [],
  supportedRTPConfigurations: [],
  supportedPayInTaxes: [],
  supportedPayOutTaxes: [],
};
