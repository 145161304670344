import { isValidUUID } from '../util/uuid';
import http from './http';

export default {
  async getPlatformAccounts(page, size, identifier, status) {
    let route = `games-install-service/platformaccount?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&uuid=${identifier}`;
      } else {
        route += `&name=${identifier}`;
      }
    }

    if (status) {
      route += `&status=${status}`;
    }

    const response = await http.get(route);
    return response;
  },
  async getPlatformAccountInstallationByUuid(platformAccountUuid) {
    const response = await http.get(`games-install-service/installation/${platformAccountUuid}`);
    return response;
  },
  async installPlatformAccount(platformAccountUuid, platformHttpRouteId, platformProviderId) {
    const response = await http.put('games-install-service/install/platform', {
      platformAccountUuid,
      serviceAccountSubscriberHttpRouteId: platformHttpRouteId,
      platformProviderId,
    });
    return response;
  },
  async installServiceAccountSubscription(serviceAccountSubscriptionUuid, serviceHttpRouteId) {
    const response = await http.put('games-install-service/install/serviceaccountsubscription', {
      serviceAccountSubscriptionUuid,
      serviceHttpRouteId,
    });
    return response;
  },
  async getCompanyDeactivatedStatus(platformAccountUuid) {
    const response = await http.get(`nsoft-games-service/companies/deactivated/${platformAccountUuid}`);
    return response;
  },
  async activatePlatformAccount(platformAccountUuid) {
    const response = await http.put(`nsoft-games-service/companies/deactivated/${platformAccountUuid}`);
    return response;
  },
};
