import vuetify from '../plugins/vuetify';

export default {
  loading: false,
  maxNotificationCount: 5,
  notifications: undefined,
  notificationTypes: {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
  },
  formRules: {
    required: (v) => !!v || vuetify.framework.lang.t('$vuetify.requiredField'),
    uuid: (v) => /[\w\d]{8}-[\w\d]{4}-[\w\d]{4}-[\w\d]{4}-[\w\d]{12}/.test(v)
    || vuetify.framework.lang.t('$vuetify.notValidUUIDField'),
    isPositiveNumber: (v) => {
      if (v) {
        if (!v.toString().trim()) return true;
        if (!Number.isNaN(parseFloat(v)) && v > 0) return true;
        return vuetify.framework.lang.t('$vuetify.notValidField');
      }
      return true;
    },
    isRoundNumber: (v) => /^[0-9]+$/.test(v) || vuetify.framework.lang.t('$vuetify.requiresIntegers'),
    hasMaximumTwoDecimals: (v) => /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || vuetify.framework.lang.t('$vuetify.notValidDecimalFormat'),
    isLessOrEqualThan: (number) => (v) => v <= number || `${vuetify.framework.lang.t('$vuetify.notValidField')} (Max ${number})`,
  },
};
