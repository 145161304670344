import http from './http';

export default {
  async getLonCompanies() {
    const response = await http.get('nsoft-games-service/tax-integrations/LON/companies');
    return response;
  },
  async getRoCompanies() {
    const response = await http.get('nsoft-games-service/tax-integrations/RO/companies');
    return response;
  },
  async getDeCompanies() {
    const response = await http.get('nsoft-games-service/tax-integrations/DE/companies');
    return response;
  },
  async getSupportedTaxIntegrations() {
    const response = await http.get('nsoft-games-service/tax-integrations/taxes');
    return response;
  },
  async getCompanyTaxDetails(dataCenter, companyId) {
    const response = await http.get(`nsoft-games-service/tax-integrations/company/${dataCenter}/${companyId}`);
    return response;
  },
  async updateCompanyTaxDetails(dataCenter, companyId, companyTaxDetails) {
    const response = await http.put(`nsoft-games-service/tax-integrations/company/${dataCenter}/${companyId}`, companyTaxDetails);
    return response;
  },
};
