import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  tableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.tableHeaders.map(convert);
  },
  jackpots: (state) => state.jackpots.data,
  pagination: (state) => state.jackpots.pagination,
  jackpot: (state) => state.currentJackpotConfig,
  uniqueGames: (state) => state.uniqueGames,
  channels: (state) => {
    const convert = (channelName) => {
      assign(channelName, {
        name: vuetify.framework.lang.t(`$vuetify.${channelName.translationKey}`),
      });
      return channelName;
    };
    return state.channels.map(convert);
  },
  supportedProducts: (state) => state.supportedProducts,
  hasMaxFundConfigs: (state) => state.currentJackpotConfig.fundConfigs.length >= 3,
};
