import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from '../i18n/en';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#C02222',
      },
    },
  },
});
