import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  orderStatuses: (state) => state.orderStatuses,
  tableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.tableHeaders.map(convert);
  },
  sevenProductOrders: (state) => state.sevenProductOrders.data,
  pagination: (state) => state.sevenProductOrders.pagination,
  currentProductOrder: (state) => state.currentProductOrder,
  currentProductDeployments: (state) => state.currentProductDeployments || [],
};
