import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  installationStatuses: (state) => state.installationStatuses,
  tableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.tableHeaders.map(convert);
  },
  platformAccounts: (state) => state.platformAccounts.data,
  pagination: (state) => state.platformAccounts.pagination,
  platformAccount: (state) => state.currentPlatformAccount,
  serviceAccountSubscriptions: (state) => state.currentServiceAccountSubscriptions,
};
