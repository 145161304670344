import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getActiveFreeBets({ commit, dispatch }) {
    try {
      const response = await api.freeBetsService.getActiveFreeBets();
      commit(types.SET_ACTIVE_FREE_BETS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getExpiredFreeBets({ commit, dispatch }) {
    try {
      const response = await api.freeBetsService.getExpiredFreeBets();
      commit(types.SET_EXPIRED_FREE_BETS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async expireFreeBet({ state, commit, dispatch }, payload) {
    try {
      const response = await api.freeBetsService.expireBet(payload.awardId, payload.playerId);
      commit(types.UPDATE_ACTIVE_FREE_BET, response.data);
      commit(types.SET_ACTIVE_FREE_BETS, state.activeFreeBets);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getActiveAwardDetails({ state, commit, dispatch }, payload) {
    try {
      const response = await api.freeBetsService.getAwardDetails(payload.awardId);
      const tickets = response.data;
      commit(types.ADD_ACTIVE_AWARD_DETAILS, { ...payload, tickets });
      commit(types.SET_ACTIVE_FREE_BETS, state.activeFreeBets);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getExpiredAwardDetails({ state, commit, dispatch }, payload) {
    try {
      const response = await api.freeBetsService.getAwardDetails(payload.awardId);
      const tickets = response.data;
      commit(types.ADD_EXPIRED_AWARD_DETAILS, { ...payload, tickets });
      commit(types.SET_EXPIRED_FREE_BETS, state.expiredFreeBets);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
};
