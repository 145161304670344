import { filter } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SHOW_NOTIFICATION](state, payload) {
    if (!payload) {
      return;
    }

    if (!state.notifications) {
      state.notifications = [];
    }

    if (state.notifications.length >= state.maxNotificationCount) {
      state.notifications.shift();
    }

    const notification = {
      id: new Date().getTime() + Math.random(),
      type: undefined,
      translationKey: undefined,
      text: undefined,
    };

    if (payload.type === state.notificationTypes.INFO
      || payload.type === state.notificationTypes.SUCCESS
      || payload.type === state.notificationTypes.ERROR) {
      notification.type = payload.type;
    }

    notification.text = payload.text;
    notification.translationKey = payload.translationKey;

    if (notification.type && !notification.text && !notification.translationKey) {
      notification.translationKey = notification.type;
    }

    state.notifications.push(notification);
  },
  [types.HIDE_NOTIFICATION](state, payload) {
    const { notifications } = state;
    state.notifications = filter(notifications, (notification) => notification.id !== payload);
  },
};
